// display
.hidden {
  display: none;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-start {
  justify-content: flex-start !important;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between !important;
}
.justify-around {
  justify-content: space-around;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-stretch {
  align-items: stretch;
}
.flex-grow {
  flex-grow: 1;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden;
}
.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll;
}

// postions
.position-relative {
  position: relative;
}
.position-bottom {
  position: absolute;
  bottom: 0;
}
.text-center {
  text-align: center;
}
.align-middle {
  vertical-align: middle;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.x-center {
  left: 50%;
  transform: translateX(-50%);
}
.y-center {
  top: 50%;
  transform: translateY(-50%);
}
.clr-ifcode {
  color: #d6191f;
}
.mar-r-1-rem {
  margin-right: 1rem;
}
.clr-red{
  color: red;
}